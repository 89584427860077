<template>
  <div>
    <!-- 配送费弹窗 -->
    <el-dialog title="增加配送费" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form label-width="45%" label-position="right" :model="localDataForm" ref="ruleForm" :rules="rules">
        <el-row>
          <el-col>
            <el-form-item label="订单配送费(元)">
              <el-input v-model="ruleForm.deliveryAmount" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="增加配送费(元)" prop="refundAmount">
              <el-input-number style="width: 255px;border-radius: 10px;" v-model="localDataForm.refundAmount" :min="0"
                :precision="1" :step="0.1"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="累计配送费(元)">
              <el-input v-model="totalDeliveryCharge" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" plain @click="onCancel">取 消</el-button>
        <el-button type="primary" size="small" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>


</template>

<script>
export default {
  props: {
    // 接受父级组件的显示标记，用于被watch
    showFlag: {
      type: Boolean
    },
    ruleForm: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      //  prop 值的局部数据属性  
      localDataForm: {
        ...this.ruleForm,
        refundAmount: 0,//增加运费
      },


      rules: {
        refundAmount: [
          { required: true, },
          {
            validator: (rule, value, callback) => {
              // 检查值是否大于0  
              if (value == '' || isNaN(parseFloat(value))) {
                // callback(new Error('请输入有效的数字'));
                callback(new Error('增加的配送费需要大于0.0'));
              } else {
                callback(); // 验证成功  
              }
            },
            trigger: 'blur' // 触发验证的事件  
          }
        ],
      },
    }
  },
  watch: {
    // 观察父级组件的showFlag，并将showFlag的最新值设置给dialogVisible
    showFlag(newVal) {
      this.dialogVisible = newVal
    },
  },
  created() {
  },
  computed: {
    totalDeliveryCharge() {
      const deliveryAmount = Number(this.ruleForm.deliveryAmount);
      const refundAmount = Number(this.localDataForm.refundAmount);
      const total = deliveryAmount + refundAmount;
      return parseFloat(total.toFixed(1)); // 限制小数点后两位  
    },
  },
  methods: {
    // 弹窗输入(优化)
    async submitForm(formName) {
      // console.log('ruleForm', this.ruleForm);
      try {
        // 校验表单 
        await new Promise((resolve, reject) => {
          this.$refs[formName].validate(valid => {
            if (!valid) {
              this.$message.error('请输入有效的数字!');
              reject(new Error('表单校验失败')); // 拒绝Promise并传递错误  
            } else {
              resolve(); // 校验成功，解决Promise  
            }
          });
        });
        // 准备要发送的数据  
        const data = {
          orderId: this.ruleForm.orderId,
          // deliveryAmount: this.totalDeliveryCharge,//总运费
          deliveryAmount: this.localDataForm.refundAmount,//运费
        };
        // console.log('准备要发送的数据 ', data);
        // 申请更改运费
        let response = await this.$axios.post(this.$api.ordersFreight, data);
        // 处理响应结果  
        const { code } = response.data;
        if (code == 100) {
          this.$message({ message: '恭喜你，处理成功!', type: 'success' });
          this.onCancel();//重置+关闭弹窗
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 检验重置
    resetForm() {
      this.localDataForm = {
        refundAmount: 0
      };//清空对象
      this.$refs.ruleForm.resetFields();
    },

    // 触发父级组件的closeChildDialog
    onCancel() {
      this.resetForm()//重置校验
      this.$emit('closeChildDialog')

    },
  },
}
</script>

<style scoped lang='scss'></style>
